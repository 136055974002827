import React from "react";

export default () => (
  <div
    className="container"
    style={{ position: "absolute", height: "100%", width: "100%" }}
  >
    <iframe
      title="Get stick bugged lol"
      width="100%"
      height="100%"
      src="https://www.youtube-nocookie.com/embed/fC7oUOUEEi4?controls=0&autoplay=1&loop=1&playlist=fC7oUOUEEi4"
      frameBorder="0"
      allow="accelerometer; autoplay; loop; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
);
